.form-check-label {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.form-check-input {
  width: 13px;
  height: 13px;
  margin-right: 5px;
  margin-top: 0 !important;
  cursor: pointer;
}

.form-control {
  &.is-invalid,
  &.ng-dirty.ng-invalid {
    border-color: var(--red) !important;

    &:focus {
      box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%);
    }
  }

  &:disabled,
  &[readonly] {
    opacity: 0.7 !important;
  }
}

.form-control-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url('/assets/chevron-down.svg') 95% / 20px no-repeat !important;

  /* CAUTION: Internet Explorer hackery ahead */
  &::-ms-expand {
    display: none; /* Remove default arrow in Internet Explorer 10 and 11 */
  }
}
