@import '../variable.scss';

button {
  background: inherit;
  box-shadow: none !important;
  border: 0;

  &.close {
    padding: 0;
    background-color: transparent;
    border: 0;
  }
}

// Buttons
@mixin btn {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  border-radius: 4px;
  padding: 12px;

  &:disabled,
  &[readonly] {
    opacity: 0.7;
  }
}

.btn {
  @include btn;
}

.btn-gray {
  @include btn;
  background: var(--gray);
  color: var(--blue-2);

  .icon-left {
    padding-left: 4px;
  }
}

.btn-primary {
  @include btn;
  background-color: var(--primary-blue) !important;
  border: 1px solid var(--blue-1) !important;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 179, 222, 0.16) !important;
  color: var(--white);
  font-weight: bold;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.12);

  &:active {
    border: 1px solid;
    border-color: var(--primary-blue) !important;
    background-color: var(--white) !important;
    color: var(--primary-blue) !important;
  }

  &:hover {
    text-decoration: none;
    opacity: 0.8;
  }
}

.btn-outline-primary {
  @include btn;
  background-color: var(--white) !important;
  border: 1px solid var(--blue-1) !important;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 179, 222, 0.16) !important;
  color: var(--primary-blue) !important;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.12);

  &:active {
    border: 1px solid;
    border-color: var(--primary-blue) !important;
    background-color: var(--primary-blue) !important;
    color: var(--white) !important;
  }

  &:hover {
    text-decoration: none;
    opacity: 0.8;
  }
}

.btn-white {
  @include btn;
  background-color: white !important;
  border: 1px solid white !important;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 179, 222, 0.16) !important;
  color: var(--primary-blue);
  font-weight: bold;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.12);

  &:active {
    border: 1px solid;
    border-color: white !important;
    background-color: white !important;
    color: var(--primary-blue) !important;
  }

  &:hover {
    text-decoration: none;
    opacity: 0.8;
    color: var(--primary-blue) !important;
  }
}

.btn-red {
  @include btn;
  background: var(--red-4);
  color: white;
}

.plaid-button-link {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(--primary-blue) !important;
  background: none !important;
  height: auto !important;
  padding: 0 !important;
}
