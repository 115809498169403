@import 'variable';
@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

// Proxima Nova, Proxima Nova Alt
@import './shared-assets/fonts/ProximaNova/stylesheet.css';

h1 {
  font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
  font-weight: bold;
}

@font-face {
  font-family: openSans;
  src: url('/assets/openSans/OpenSans-Regular.ttf');
  src: url('/fonts/openSans'), format('truetype');
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
  -webkit-tap-highlight-color: transparent;
}
html {
  scroll-behavior: smooth;
}
.myCustomModalClass .modal-dialog {
  max-width: 900px !important;
  text-align: center;
}

.cdk-overlay-container {
  z-index: 9999999999999999 !important;
}

a {
  font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
  text-decoration: none;
  outline: 0;
  cursor: pointer;
}

a:active,
a:hover {
  outline: 0;
}

// h1,
// h2,
// h3,
// h4,
// h5,
// h6,
// p,
// ol,
// ul,
// li,
// fieldset,
// form,
// label,
// table,
// tbody,
// tr,
// th,
// td,
// figure,
// footer,
// header,
// menu,
// nav,
// section,
// audio,
// video,
// button {
//   margin: 0;
//   padding: 0;
//   border: 0;
// }

// button {
//   background: inherit;
//   box-shadow: none !important;
// }

// table {
//   width: 100%;
// }

body {
  margin: 0;
  font-family: 'Proxima Nova', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-print-color-adjust: exact !important;
}

#printableArea,
#reportArea {
  background-color: white;
}

img {
  height: auto;
  max-width: 100%;
  vertical-align: top;
  border: 0;
}
// .container {
//   max-width: 1366px;
//   width: 100%;
//   padding: 20px;
//   position: relative;
//   display: block;
//   margin: 0 auto;
// }

// ._form-wrapper {
//   z-index: 9999999999999 !important;
// }
html,
body {
  height: auto;
  background-color: white !important;
}
body {
  margin: 0;
  font-family: 'Proxima Nova', Helvetica, sans-serif;
}

ol,
ul {
  padding-left: 0;
}

@media print {
  body,
  html,
  #print-section {
    font-size: 10pt !important;
    height: 100% !important;
  }
}

@media screen, print {
  body {
    line-height: 1.2 !important;
  }
}
@page {
  size: 25cm 35.7cm;
  margin: 5mm 5mm 5mm 5mm; /* change the margins as you want them to be. */
}

// .myListingBtn {
//   background-color: var(--primary-blue) !important;
//   border-color: var(--primary-blue) !important;
// }
// #vouched-element {
//   .vouched .container {
//     max-width: 100vw !important;
//   }
// }

.spinner-border {
  color: var(--primary-blue);
}

.gap-1 {
  gap: 1rem;
}

.gap-2 {
  gap: 2rem;
}

.gap-3 {
  gap: 3rem;
}

.btn:disabled {
  cursor: not-allowed !important;
}

.inactive {
  opacity: 0.5;
}

// TODO fix theming
@import './scss/accordion.scss';
@import './scss/buttons.scss';
@import './scss/page.scss';
@import './scss/toast.scss';
@import './scss/dropdown.scss';
@import './scss/forms.scss';
