@import '../variable.scss';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';

// Page
%page {
  margin: 0 24px;

  @include media-breakpoint-down(sm) {
    margin: 0 16px;
  }

  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: var(--darkest);
    margin-bottom: 0;
    padding: 16px 0;

    @include media-breakpoint-down(sm) {
      padding: 24px 0;
    }
  }

  hr {
    margin-top: 0;
    margin-bottom: 24px;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}
.pac-container {
  z-index: 10000000;
}
