@font-face {
  font-family: 'Proxima Nova';
  src: url('ProximaNovaT-Thin.eot');
  src: url('ProximaNovaT-Thin.eot?#iefix') format('embedded-opentype'), url('ProximaNovaT-Thin.woff2') format('woff2'),
    url('ProximaNovaT-Thin.woff') format('woff'), url('ProximaNovaT-Thin.ttf') format('truetype'),
    url('ProximaNovaT-Thin.svg#ProximaNovaT-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('ProximaNovaA-Light.eot');
  src: url('ProximaNovaA-Light.eot?#iefix') format('embedded-opentype'), url('ProximaNovaA-Light.woff2') format('woff2'),
    url('ProximaNovaA-Light.woff') format('woff'), url('ProximaNovaA-Light.ttf') format('truetype'),
    url('ProximaNovaA-Light.svg#ProximaNovaA-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('ProximaNova-Regular.eot');
  src: url('ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
    url('ProximaNova-Regular.woff2') format('woff2'), url('ProximaNova-Regular.woff') format('woff'),
    url('ProximaNova-Regular.ttf') format('truetype'), url('ProximaNova-Regular.svg#ProximaNova-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('ProximaNova-Medium.eot');
  src: url('ProximaNova-Medium.eot?#iefix') format('embedded-opentype'), url('ProximaNova-Medium.woff2') format('woff2'),
    url('ProximaNova-Medium.woff') format('woff'), url('ProximaNova-Medium.ttf') format('truetype'),
    url('ProximaNova-Medium.svg#ProximaNova-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('ProximaNova-Bold.eot');
  src: url('ProximaNova-Bold.eot?#iefix') format('embedded-opentype'), url('ProximaNova-Bold.woff2') format('woff2'),
    url('ProximaNova-Bold.woff') format('woff'), url('ProximaNova-Bold.ttf') format('truetype'),
    url('ProximaNova-Bold.svg#ProximaNova-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('ProximaNova-Extrabld.eot');
  src: url('ProximaNova-Extrabld.eot?#iefix') format('embedded-opentype'),
    url('ProximaNova-Extrabld.woff2') format('woff2'), url('ProximaNova-Extrabld.woff') format('woff'),
    url('ProximaNova-Extrabld.ttf') format('truetype'),
    url('ProximaNova-Extrabld.svg#ProximaNova-Extrabld') format('svg');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('ProximaNova-Black.eot');
  src: url('ProximaNova-Black.eot?#iefix') format('embedded-opentype'), url('ProximaNova-Black.woff2') format('woff2'),
    url('ProximaNova-Black.woff') format('woff'), url('ProximaNova-Black.ttf') format('truetype'),
    url('ProximaNova-Black.svg#ProximaNova-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova Alt';
  src: url('ProximaNovaA-Thin.eot');
  src: url('ProximaNovaA-Thin.eot?#iefix') format('embedded-opentype'), url('ProximaNovaA-Thin.woff2') format('woff2'),
    url('ProximaNovaA-Thin.woff') format('woff'), url('ProximaNovaA-Thin.ttf') format('truetype'),
    url('ProximaNovaA-Thin.svg#ProximaNovaA-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova Alt';
  src: url('ProximaNovaA-Light.eot');
  src: url('ProximaNovaA-Light.eot?#iefix') format('embedded-opentype'), url('ProximaNovaA-Light.woff2') format('woff2'),
    url('ProximaNovaA-Light.woff') format('woff'), url('ProximaNovaA-Light.ttf') format('truetype'),
    url('ProximaNovaA-Light.svg#ProximaNovaA-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova Alt';
  src: url('ProximaNovaA-Bold.eot');
  src: url('ProximaNovaA-Bold.eot?#iefix') format('embedded-opentype'), url('ProximaNovaA-Bold.woff2') format('woff2'),
    url('ProximaNovaA-Bold.woff') format('woff'), url('ProximaNovaA-Bold.ttf') format('truetype'),
    url('ProximaNovaA-Bold.svg#ProximaNovaA-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
