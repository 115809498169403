@import '../../node_modules/ngx-toastr/toastr';

.toast-container {
  @include media-breakpoint-down(sm) {
    width: 100%;
    left: 0;
    margin: 0 auto;
    padding: 0 12px;
  }

  .ngx-toastr {
    width: 360px;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}
