@import '../variable.scss';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';

.app-accordion.accordion {
  .accordion-item {
    box-shadow:
      0px 1px 2px rgba(0, 0, 0, 0.08),
      0px 3px 7px rgba(0, 0, 0, 0.08);
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 4px;
  }

  & > .accordion-item {
    min-width: 250px;

    & > .accordion-header {
      padding: 0;
      background-color: var(--white);
      border-bottom: 0;

      .header-container {
        padding: 16px 24px;
        display: flex;
        width: 100%;

        &.accordion-button::after {
          display: none;
        }

        &.accordion-button:not(.collapsed) {
          color: inherit;
          background-color: var(--white);
        }

        &:focus-visible {
          outline: none;
        }

        .header-title {
          margin-right: auto;
          font-weight: bold;
          font-size: 16px;
          line-height: 20px;
          display: flex;
          align-self: center;
          align-items: center;
          color: var(--blue-2);
        }

        .btn-gray {
          margin-left: auto;
        }

        .btn-toggle {
          display: flex;
          align-items: center;

          .icon {
            background-image: url('/assets/chevron-down.svg');
            background-position: center;
            width: 20px;
            height: 20px;
            padding-left: 4px;

            &.isExpanded {
              background-image: url('/assets/chevron-up.svg');
            }
          }

          em {
            &.fa-minus,
            &.fa-plus {
              color: var(--blue-2);
            }
          }
        }
      }
    }

    & .accordion-body {
      padding: 0;

      &.body,
      .body {
        padding: 0 24px 24px 24px;

        @include media-breakpoint-down(sm) {
          padding: 0 16px 16px 16px;
        }
      }

      .footer {
        display: flex;
        padding: 24px;
        border-top: 1px solid var(--gray-2);

        @include media-breakpoint-down(sm) {
          padding: 16px;
        }
      }
    }
  }
}
